import { BaseRepository } from '@/api/baseRepository';
import { replaceEnvPath } from '@/shared/utils';
import { convertQueryToGetParams, convertQueryToJsonRpc } from '@/shared/api';
import enums from "@/config/enums";

const BACKEND_ENDPOINTS = {
  ME_PROJECTS: { url: '/v2/me/projects' },
  ME_PROJECTS_QUERY: {
    url: '/v2/me/projects/query',
    filter: {
      id: { type: enums.API_FILTER_TYPES.IN_INT },
      country_id: { type: enums.API_FILTER_TYPES.IN_INT },
      region_id: { type: enums.API_FILTER_TYPES.IN_INT },
      district_id: { type: enums.API_FILTER_TYPES.IN_INT },
      au_path: { type: enums.API_FILTER_TYPES.IN_STRING },
      uncertainty_id: { type: enums.API_FILTER_TYPES.IN_NOT_IN_INT },
      contract_type_id: { type: enums.API_FILTER_TYPES.IN_NOT_IN_INT },
      ecocertified_types: { type: enums.API_FILTER_TYPES.IN_INT },
      certification_type_id: { type: enums.API_FILTER_TYPES.IN_INT },
      stage_id: { type: enums.API_FILTER_TYPES.IN_INT },
      company_roles: { type: enums.API_FILTER_TYPES.IN_NOT_IN_INT },
      materials: { type: enums.API_FILTER_TYPES.IN_NOT_IN_INT },
      companies: { type: enums.API_FILTER_TYPES.IN_INT },
      contacts: { type: enums.API_FILTER_TYPES.IN_INT },
      categories: { type: enums.API_FILTER_TYPES.IN_NOT_IN_INT },
      dev_types: { type: enums.API_FILTER_TYPES.IN_NOT_IN_INT },
      residence_forms: { type: enums.API_FILTER_TYPES.IN_INT },
      start_date: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      finish_date: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      value: { type: enums.API_FILTER_TYPES.GTE_LTE_INT },
      buildings: { type: enums.API_FILTER_TYPES.GTE_LTE_INT },
      apartments: { type: enums.API_FILTER_TYPES.GTE_LTE_INT },
      floors: { type: enums.API_FILTER_TYPES.GTE_LTE_INT },
      underground_floors: { type: enums.API_FILTER_TYPES.GTE_LTE_INT },
      gross_area: { type: enums.API_FILTER_TYPES.GTE_LTE_FLOAT },
      construction_area: { type: enums.API_FILTER_TYPES.GTE_LTE_FLOAT },
      gross_volume: { type: enums.API_FILTER_TYPES.GTE_LTE_FLOAT },
      renovation_area: { type: enums.API_FILTER_TYPES.GTE_LTE_FLOAT },
      demolition_area: { type: enums.API_FILTER_TYPES.GTE_LTE_FLOAT },
      decontamination_area: { type: enums.API_FILTER_TYPES.GTE_LTE_FLOAT },
      usage_area: { type: enums.API_FILTER_TYPES.GTE_LTE_FLOAT },
      source_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      updated_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      postcode: { type: enums.API_FILTER_TYPES.GTE_LTE_RANGES },
      latest_update_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      contact_company_role_agg: { type: enums.API_FILTER_TYPES.IN_NOT_IN_AGG_STRING },
      company_company_role_agg: { type: enums.API_FILTER_TYPES.IN_NOT_IN_AGG_STRING },
      company_contact_agg: { type: enums.API_FILTER_TYPES.IN_STRING },
      item_created_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      item_modified_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      version_created_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      version_modified_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      framework_agreement: { type: enums.API_FILTER_TYPES.EQ_BOOLEAN_NUMBER },
      project_type_id: { type: enums.API_FILTER_TYPES.IN_NOT_IN_INT },
      parish_id: { type: enums.API_FILTER_TYPES.IN_NOT_IN_INT },
      planning_stage_id: { type: enums.API_FILTER_TYPES.IN_NOT_IN_INT },
      company_role_assigned_date: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      crafts: { type: enums.API_FILTER_TYPES.IN_INT },
      'users.user_project_status_id': { type: enums.API_FILTER_TYPES.IN_NOT_IN_INT },
      'users.viewed': { type: enums.API_FILTER_TYPES.EQ_BOOLEAN_NUMBER },
      'users.tags': { type: enums.API_FILTER_TYPES.IN_INT },
      responsible_ids: { type: enums.API_FILTER_TYPES.IN_INT },
      'users.modified_at': { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      'users.delivered_at': { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      'users.user_id': { type: enums.API_FILTER_TYPES.IN_INT },
      ecocertified_type_id: { type: enums.API_FILTER_TYPES.IN_INT },
      developer_company_type_id: { type: enums.API_FILTER_TYPES.IN_INT },
      parking_lots: { type: enums.API_FILTER_TYPES.GTE_LTE_INT },
      building_purpose: { type: enums.API_FILTER_TYPES.IN_INT },
      areas_by_or: { type: enums.API_FILTER_TYPES.GTE_LTE_INT },
      publish_source_id: { type: enums.API_FILTER_TYPES.IN_INT },
      expected_tender_roles: { type: enums.API_FILTER_TYPES.IN_INT },
      planning_approval_date: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
    },
  },
  ME_PROJECT: { url: '/v1/me/projects/{project_id}' },
  ME_PROJECT_VIEW: { url: '/v1/me/projects/{project_id}/view' },
  ME_PROJECT_TAG: { url: '/v1/me/projects/{project_id}/tag' },
  ME_PROJECT_RESPONSIBLE: { url: '/v1/me/projects/{project_id}/responsible' },
  ME_PROJECTS_RESPONSIBLE: { url: '/v2/me/projects/responsible' },
  ME_PROJECTS_BULK: { url: '/v1/me/bulk/projects' },
  ME_PROJECTS_BULK_RESPONSIBLE: { url: '/v1/me/bulk/projects/responsible' },

  // Sales / Tenders
  ME_TENDERS: { url: '/v2/me/tenders' },
  ME_TENDERS_QUERY: {
    url: '/v2/me/tenders/query',
    filter: {
      id: { type: enums.API_FILTER_TYPES.IN_INT },
      tender_subject_id: { type: enums.API_FILTER_TYPES.IN_INT },
      tender_type_id: { type: enums.API_FILTER_TYPES.IN_INT },
      tender_status_id: { type: enums.API_FILTER_TYPES.IN_INT },
      company_role_id: { type: enums.API_FILTER_TYPES.IN_INT },
      framework_agreement: { type: enums.API_FILTER_TYPES.EQ_BOOLEAN_NUMBER },
      first_day_materials_range: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      last_day_materials_range: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      last_day_questions_range: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      last_day_application_range: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      prequalification_date_range: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      country_id: { type: enums.API_FILTER_TYPES.IN_INT },
      region_id: { type: enums.API_FILTER_TYPES.IN_INT },
      district_id: { type: enums.API_FILTER_TYPES.IN_INT },
      au_path: { type: enums.API_FILTER_TYPES.IN_STRING },
      start_date: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      finish_date: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      value: { type: enums.API_FILTER_TYPES.GTE_LTE_INT },
      categories: { type: enums.API_FILTER_TYPES.IN_NOT_IN_INT },
      dev_types: { type: enums.API_FILTER_TYPES.IN_NOT_IN_INT },
      materials: { type: enums.API_FILTER_TYPES.IN_INT },
      source_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      updated_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      postcode: { type: enums.API_FILTER_TYPES.GTE_LTE_RANGES },
      latest_update_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      item_created_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      item_modified_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      version_created_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      version_modified_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      archived: { type: enums.API_FILTER_TYPES.EQ_BOOLEAN_NUMBER },
      parish_id: { type: enums.API_FILTER_TYPES.IN_NOT_IN_INT },
      'users.viewed': { type: enums.API_FILTER_TYPES.EQ_BOOLEAN_NUMBER },
      'users.tags': { type: enums.API_FILTER_TYPES.IN_INT },
      responsible_ids: { type: enums.API_FILTER_TYPES.IN_INT },
      'users.modified_at': { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      'users.delivered_at': { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      'users.user_id': { type: enums.API_FILTER_TYPES.IN_INT },
      'users.user_tender_status_id': { type: enums.API_FILTER_TYPES.IN_INT },
    },
  },
  ME_TENDER: { url: '/v1/me/tenders/{tender_id}' },
  ME_TENDER_VIEW: { url: '/v1/me/tenders/{tender_id}/view' },
  ME_TENDER_TAG: { url: '/v1/me/tenders/{tender_id}/tag' },
  ME_TENDER_RESPONSIBLE: { url: '/v1/me/tenders/{tender_id}/responsible' },
  ME_TENDERS_RESPONSIBLE: { url: '/v2/me/tenders/responsible' },
  ME_TENDERS_BULK: { url: '/v1/me/bulk/tenders' },
  ME_TENDERS_BULK_RESPONSIBLE: { url: '/v1/me/bulk/tenders/responsible' },

  // Sales / Companies
  ME_COMPANIES: { url: '/v2/me/companies' },
  ME_COMPANIES_QUERY: {
    url: '/v2/me/companies/query',
    filter: {
      id: { type: enums.API_FILTER_TYPES.IN_INT },
      company_type_id: { type: enums.API_FILTER_TYPES.IN_NOT_IN_INT },
      country_id: { type: enums.API_FILTER_TYPES.IN_NOT_IN_INT },
      region_id: { type: enums.API_FILTER_TYPES.IN_INT },
      district_id: { type: enums.API_FILTER_TYPES.IN_INT },
      au_path: { type: enums.API_FILTER_TYPES.IN_STRING },
      project_categories: { type: enums.API_FILTER_TYPES.IN_INT },
      project_dev_types: { type: enums.API_FILTER_TYPES.IN_INT },
      project_countries: { type: enums.API_FILTER_TYPES.IN_INT },
      project_company_roles: { type: enums.API_FILTER_TYPES.IN_INT },
      project_start_range: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      project_value_range: { type: enums.API_FILTER_TYPES.GTE_LTE_INT },
      project_count: { type: enums.API_FILTER_TYPES.GTE_LTE_INT },
      contact_count: { type: enums.API_FILTER_TYPES.GTE_LTE_INT },
      contacts: { type: enums.API_FILTER_TYPES.IN_INT },
      active_contacts: { type: enums.API_FILTER_TYPES.IN_INT },
      expired_contacts: { type: enums.API_FILTER_TYPES.IN_INT },
      source_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      updated_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      latest_update_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      item_created_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      item_modified_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      version_created_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      version_modified_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      'projects.id': { type: enums.API_FILTER_TYPES.IN_NOT_IN_INT },
      'projects.categories': { type: enums.API_FILTER_TYPES.IN_NOT_IN_INT },
      'projects.dev_types': { type: enums.API_FILTER_TYPES.IN_NOT_IN_INT },
      'projects.roles': { type: enums.API_FILTER_TYPES.IN_NOT_IN_INT },
      'projects.country_id': { type: enums.API_FILTER_TYPES.IN_INT },
      'projects.region_id': { type: enums.API_FILTER_TYPES.IN_INT },
      'projects.district_id': { type: enums.API_FILTER_TYPES.IN_INT },
      'projects.postcode': { type: enums.API_FILTER_TYPES.GTE_LTE_RANGES },
      'projects.start_date': { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      'projects.finish_date': { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      'projects.value': { type: enums.API_FILTER_TYPES.GTE_LTE_INT },
      'projects.role_assigned_date': { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      'projects.au_path': { type: enums.API_FILTER_TYPES.IN_INT },
      parish_id: { type: enums.API_FILTER_TYPES.IN_NOT_IN_INT },
      'users.user_id': { type: enums.API_FILTER_TYPES.IN_NOT_IN_INT },
      'users.client_company_folder_id': { type: enums.API_FILTER_TYPES.IN_INT },
      'users.viewed': { type: enums.API_FILTER_TYPES.EQ_BOOLEAN_NUMBER },
      'users.tags': { type: enums.API_FILTER_TYPES.IN_INT },
      'users.modified_at': { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      'users.delivered_at': { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
    },
  },
  ME_COMPANY: { url: '/v1/me/companies/{company_id}' },
  ME_COMPANY_VIEW: { url: '/v1/me/companies/{company_id}/view' },
  ME_COMPANIES_BULK: { url: '/v1/me/bulk/companies' },

  // Sales / Contacts
  ME_CONTACTS: { url: '/v2/me/contacts' },
  ME_CONTACTS_QUERY: {
    url: '/v2/me/contacts/query',
    filter: {
      id: { type: enums.API_FILTER_TYPES.IN_INT },
      project_count: { type: enums.API_FILTER_TYPES.GTE_LTE_INT },
      company_count: { type: enums.API_FILTER_TYPES.GTE_LTE_INT },
      original_companies: { type: enums.API_FILTER_TYPES.IN_INT },
      project_companies: { type: enums.API_FILTER_TYPES.IN_INT },
      all_companies: { type: enums.API_FILTER_TYPES.IN_INT },
      project_countries: { type: enums.API_FILTER_TYPES.IN_INT },
      project_categories: { type: enums.API_FILTER_TYPES.IN_INT },
      project_dev_types: { type: enums.API_FILTER_TYPES.IN_INT },
      project_company_roles: { type: enums.API_FILTER_TYPES.IN_INT },
      project_contact_roles: { type: enums.API_FILTER_TYPES.IN_INT },
      project_start_range: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      project_value_range: { type: enums.API_FILTER_TYPES.GTE_LTE_INT },
      source_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      updated_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      latest_update_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      item_created_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      item_modified_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      version_created_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      version_modified_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      'users.user_id': { type: enums.API_FILTER_TYPES.IN_NOT_IN_INT },
      'users.viewed': { type: enums.API_FILTER_TYPES.EQ_BOOLEAN_NUMBER },
      'users.tags': { type: enums.API_FILTER_TYPES.IN_INT },
      'users.modified_at': { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      'users.delivered_at': { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
    },
  },
  ME_CONTACT: { url: '/v1/me/contacts/{contact_id}' },
  ME_CONTACT_VIEW: { url: '/v1/me/contacts/{contact_id}/view' },
  ME_CONTACTS_BULK: { url: '/v1/me/bulk/contacts' },

  // User / Notes
  NOTES: {
    url: '/v1/me/notes',
    filter: {
      entity_type_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      entity_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      pinned: { type: enums.API_FILTER_TYPES.EQ_BOOLEAN_NUMBER },
      user_id: { type: enums.API_FILTER_TYPES.IN_INT },
      modified_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
    },
  },
  NOTE: { url: '/v1/me/notes/{notes_id}' },
  NOTE_ENTITIES: { url: '/v1/me/notes/entities' },

  // User / Activities
  ACTIVITIES: {
    url: '/v1/me/activities',
    filter: {
      entity_type_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      entity_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      start_date_time: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      activity_type_id: { type: enums.API_FILTER_TYPES.EQ_INT },
      activity_status: { type: enums.API_FILTER_TYPES.EQ_INT },
      user_id: { type: enums.API_FILTER_TYPES.IN_INT },
      assigned_to: { type: enums.API_FILTER_TYPES.IN_INT },
      done: { type: enums.API_FILTER_TYPES.EQ_BOOLEAN_NUMBER },
    },
  },
  ACTIVITY: { url: '/v1/me/activities/{activity_id}' },
  ACTIVITY_ENTITIES: { url: '/v1/me/activities/entities' },
};

// Get the projects
export function getProjects(data = null) {
  const endpoint = BACKEND_ENDPOINTS.ME_PROJECTS_QUERY;
  const query = convertQueryToJsonRpc(endpoint, data);
  return BaseRepository.post(endpoint.url, query, {
    useIdmp: false,
    query: true,
  });
}

// Add project to My Projects
export function putProject(project_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.ME_PROJECT.url, { project_id });
  return BaseRepository.put(path, data);
}

// Delete project from My Projects
export function deleteProject(project_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.ME_PROJECT.url, { project_id });
  return BaseRepository.delete(path);
}

// Mark project as viewed
export function putProjectView(project_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.ME_PROJECT_VIEW.url, { project_id });
  return BaseRepository.put(path, data);
}

// Add tag to project
export function putProjectTag(project_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.ME_PROJECT_TAG.url, { project_id });
  return BaseRepository.put(path, data);
}

// Delete tag from project
export function deleteProjectTag(project_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.ME_PROJECT_TAG.url, { project_id });
  return BaseRepository.delete(path, data);
}

// Add responsible contact to project
export function postProjectResponsible(project_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.ME_PROJECT_RESPONSIBLE.url, { project_id });
  return BaseRepository.post(path, data);
}

// Delete project responsible person
export function deleteProjectResponsible(project_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.ME_PROJECT_RESPONSIBLE.url, { project_id });
  return BaseRepository.delete(path, { data });
}

// Get the list of responsible contacts for the specified projects
export function getProjectsResponsible(data = null) {
  const endpoint = BACKEND_ENDPOINTS.ME_PROJECTS_RESPONSIBLE;
  const params = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(endpoint.url, {
    params,
    useIdmp: false,
  });
}

// Clear user projects by status
export function deleteProjects(data = {}) {
  const path = BACKEND_ENDPOINTS.ME_PROJECTS.url;
  return BaseRepository.delete(path, { data });
}

// Get the list of My Tenders
export function getTenders(data = null) {
  const endpoint = BACKEND_ENDPOINTS.ME_TENDERS_QUERY;
  const query = convertQueryToJsonRpc(endpoint, data);
  return BaseRepository.post(endpoint.url, query, {
    useIdmp: false,
    query: true,
  });
}

// Add tender to My Tender
export function putTender(tender_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.ME_TENDER.url, { tender_id });
  return BaseRepository.put(path, data);
}

// Delete tender from My Tender
export function deleteTender(tender_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.ME_TENDER.url, { tender_id });
  return BaseRepository.delete(path);
}

// Mark tender as viewed
export function putTenderView(tender_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.ME_TENDER_VIEW.url, { tender_id });
  return BaseRepository.put(path, data);
}

// Add tag to tender
export function putTenderTag(tender_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.ME_TENDER_TAG.url, { tender_id });
  return BaseRepository.put(path, data);
}

// Delete tag from tender
export function deleteTenderTag(tender_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.ME_TENDER_TAG.url, { tender_id });
  return BaseRepository.delete(path, data);
}

// Add responsible contact to tender
export function postTenderResponsible(tender_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.ME_TENDER_RESPONSIBLE.url, { tender_id });
  return BaseRepository.post(path, data);
}

// Delete tender responsible person
export function deleteTenderResponsible(tender_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.ME_TENDER_RESPONSIBLE.url, { tender_id });
  return BaseRepository.delete(path, { data });
}

// Get the list of responsible contacts for the specified tenders
export function getTendersResponsible(data = null) {
  const endpoint = BACKEND_ENDPOINTS.ME_TENDERS_RESPONSIBLE;
  const params = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(endpoint.url, {
    params,
    useIdmp: false,
  });
}

// Clear user tenders by status
export function deleteTenders(data = {}) {
  const path = BACKEND_ENDPOINTS.ME_TENDERS.url;
  return BaseRepository.delete(path, { data });
}

// Get the list of My Companies
export function getCompanies(data = null) {
  const endpoint = BACKEND_ENDPOINTS.ME_COMPANIES_QUERY;
  const query = convertQueryToJsonRpc(endpoint, data);
  return BaseRepository.post(endpoint.url, query, {
    useIdmp: false,
    query: true,
  });
}

// Add company to My Companies
export function putCompany(company_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.ME_COMPANY.url, { company_id });
  return BaseRepository.put(path, data);
}

// Delete company from My Companies
export function deleteCompany(company_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.ME_COMPANY.url, { company_id });
  return BaseRepository.delete(path);
}

// Mark company as viewed
export function putCompanyView(company_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.ME_COMPANY_VIEW.url, { company_id });
  return BaseRepository.put(path, data);
}

// Clear user companies by folder
export function deleteCompanies(data = {}) {
  const path = BACKEND_ENDPOINTS.ME_COMPANIES.url;
  return BaseRepository.delete(path, { data });
}

// Get the list of My Contacts
export function getContacts(data = null) {
  const endpoint = BACKEND_ENDPOINTS.ME_CONTACTS_QUERY;
  const query = convertQueryToJsonRpc(endpoint, data);
  return BaseRepository.post(endpoint.url, query, {
    useIdmp: false,
    query: true,
  });
}

// Add contact to My Contacts
export function putContact(contact_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.ME_CONTACT.url, { contact_id });
  return BaseRepository.put(path);
}

// Delete contact from My Contacts
export function deleteContact(contact_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.ME_CONTACT.url, { contact_id });
  return BaseRepository.delete(path);
}

// Mark contact as viewed
export function putContactView(contact_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.ME_CONTACT_VIEW.url, { contact_id });
  return BaseRepository.put(path, data);
}

export function deleteContacts(data = {}) {
  return BaseRepository.delete(BACKEND_ENDPOINTS.ME_CONTACTS.url, { data });
}

// Get the list of my and assigned to me Notes
export function getNotes(data = null) {
  const endpoint = BACKEND_ENDPOINTS.NOTES;
  const params = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(endpoint.url, {
    params,
    useIdmp: false,
  });
}

// Create new Notes
export function postNote(data) {
  const path = BACKEND_ENDPOINTS.NOTES.url;
  return BaseRepository.post(path, data);
}

// Get My Note
export function getNote(notes_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.NOTE.url, { notes_id });
  return BaseRepository.get(path, {
    useIdmp: false,
  });
}

// Update Note
export function patchNote(notes_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.NOTE.url, { notes_id });
  return BaseRepository.patch(path, data);
}

// Delete Note
export function deleteNote(notes_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.NOTE.url, { notes_id });
  return BaseRepository.delete(path);
}

// Get the entities with notes
export function getNoteEntities(data = null) {
  const endpoint = BACKEND_ENDPOINTS.NOTE_ENTITIES;
  const params = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(endpoint.url, {
    params,
    useIdmp: false,
  });
}

// Get the list of my and assigned to me activities
export function getActivities(data = null) {
  const endpoint = BACKEND_ENDPOINTS.ACTIVITIES;
  const params = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(endpoint.url, {
    params,
    useIdmp: false,
  });
}

// Create new Activity
export function postActivity(data) {
  const path = BACKEND_ENDPOINTS.ACTIVITIES.url;
  return BaseRepository.post(path, data);
}

// Get My Activity
export function getActivity(activity_id, data = {}) {
  const endpoint = BACKEND_ENDPOINTS.ACTIVITY;
  const path = replaceEnvPath(endpoint.url, { activity_id });
  const params = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(path, {
    params,
    useIdmp: false,
  });
}

// Update Activity
export function patchActivity(activity_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.ACTIVITY.url, { activity_id });
  return BaseRepository.patch(path, data);
}

// Update Activity
export function putActivity(activity_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.ACTIVITY.url, { activity_id });
  return BaseRepository.put(path, data);
}

// Delete Activity
export function deleteActivity(activity_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.ACTIVITY.url, { activity_id });
  return BaseRepository.delete(path);
}

// Get the entities with activities
export function getActivityEntities(data = null) {
  const endpoint = BACKEND_ENDPOINTS.ACTIVITY_ENTITIES;
  const params = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(endpoint.url, {
    params,
    useIdmp: false,
  });
}

// Bulk operations
export function putBulkProjects(data = {}) {
  return BaseRepository.put(BACKEND_ENDPOINTS.ME_PROJECTS_BULK.url, data);
}

export function putBulkTenders(data = {}) {
  return BaseRepository.put(BACKEND_ENDPOINTS.ME_TENDERS_BULK.url, data);
}

export function putBulkCompanies(data = {}) {
  return BaseRepository.put(BACKEND_ENDPOINTS.ME_COMPANIES_BULK.url, data);
}

export function putBulkContacts(data = {}) {
  return BaseRepository.put(BACKEND_ENDPOINTS.ME_CONTACTS_BULK.url, data);
}

export function postBulkProjectsResponsible(data) {
  return BaseRepository.post(BACKEND_ENDPOINTS.ME_PROJECTS_BULK_RESPONSIBLE.url, data);
}

export function postBulkTendersResponsible(data) {
  return BaseRepository.post(BACKEND_ENDPOINTS.ME_TENDERS_BULK_RESPONSIBLE.url, data);
}


export const SalesRepository = {
  getProjects,
  putProject,
  deleteProject,
  putProjectView,
  putProjectTag,
  deleteProjectTag,
  postProjectResponsible,
  deleteProjectResponsible,
  getProjectsResponsible,
  deleteProjects,
  getTenders,
  putTender,
  deleteTender,
  putTenderView,
  putTenderTag,
  deleteTenderTag,
  postTenderResponsible,
  deleteTenderResponsible,
  getTendersResponsible,
  deleteTenders,
  getCompanies,
  putCompany,
  deleteCompany,
  putCompanyView,
  deleteCompanies,
  getContacts,
  putContact,
  deleteContact,
  putContactView,
  getNotes,
  postNote,
  getNote,
  patchNote,
  deleteNote,
  getNoteEntities,
  getActivities,
  postActivity,
  getActivity,
  patchActivity,
  putActivity,
  deleteActivity,
  getActivityEntities,
};
